<template>
<h3>HB Product Home</h3>
</template>

<script>
export default {
  name: "ProductHome"
}
</script>

<style scoped>

</style>
